import React from 'react';
import { useState, useContext, useEffect } from "react";
import { Link,useSearchParams,useNavigate } from "react-router-dom";
import { ThemeContext } from '../../App.js';

import "../style.css";

var initSortConfig = {
    state:true,
    name:true,
    reading:true,
    status:true,
    lastUpdate:true
}

export default function Stage(props) {
    const theme = useContext(ThemeContext);

    const [sites, setSites] = useState([]);
    const [nameClass, setNameClass] = useState("name");
    const [stateClass, setStateClass] = useState("state");
    const [readingClass, setReadingClass] = useState("reading");
    const [statusClass, setStatusClass] = useState("status");
    const [lastUpdateClass, setLastUpdateClass] = useState("lastUpdate");
    const [sortConfig, setSortConfig] = useState(initSortConfig);
    const navigate = useNavigate();

    const itemStyle = {
        display:"block"
    }

    console.log("props:",props);

    const thresholdStatusStyle = {
        float:"left",
        backgroundColor:"green", 
        height:20, 
        width:20,
        borderRadius:10,
        marginRight:5
    }

    const thresholdNameStyle = {
        float:'left',
        textAlign:"center",
        fontWeight:"normal",
        marginTop:2
    }

    function findReading(readings,type) {
        for(var i=0; i<readings.length; i++) {
            if(readings[i].type == type) {
                return readings[i]
            }
        }
    }

    function findThreshold(thresholds,level) {
        var result = {status:"No Flooding",color:"green"};

        console.log()
        for(var i=0; i<thresholds.length; i++) {
            if(level > thresholds[i].value) {
                result.status = thresholds[i].name;
                result.color = thresholds[i].color
            }
        }
        return result;
    }

    function handleMouseEnter(e) {
        e.currentTarget.style.backgroundColor = "rgb(240,240,240)";
    }

    function handleMouseLeave(e) {
        e.currentTarget.style.backgroundColor = "rgb(255,255,255)";
    }

    function handleRowClick(e,d,i) {
        console.log("handleRowClick:",i);
        navigate('/' + d + '?id=' + i)
    }

    function sortLastUpdate(e) {
        console.log("sort by last update:",sortConfig);

        if (sortConfig.lastUpdate == true) {
            props.sites.sort((a, b) => {
                if(a.message === null) return 0;
                if(b.message === null) return 0;

                if(a.message.timestamp < b.message.timestamp) {
                    return -1;
                } 
    
                if (a.message.timestamp > b.message.timestamp) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state");
            setReadingClass("reading");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate ascend");

            setSortConfig({...sortConfig,lastUpdate:false});
            setSites(sites);
        } else {
            props.sites.sort((a, b) => {
                if(a.message === null) return 0;
                if(b.message === null) return 0;

                if(a.message.timestamp < b.message.timestamp) {
                    return 1;
                } 
    
                if (a.message.timestamp > b.message.timestamp) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state");
            setReadingClass("reading");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate descend");

            setSortConfig({...sortConfig,lastUpdate:true});
            setSites(sites);
        }
    }

    function sortName(e) {
        console.log("sort by name:",sortConfig);

        if (sortConfig.name == true) {
            props.sites.sort((a, b) => {
                if(a.name < b.name) {
                    return -1;
                } 
    
                if (a.name > b.name) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name descend");
            setStateClass("state");
            setReadingClass("reading");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,name:false});
            setSites(sites);
        } else {
            props.sites.sort((a, b) => {
                if(a.name < b.name) {
                    return 1;
                } 
    
                if (a.name > b.name) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name ascend");
            setStateClass("state");
            setReadingClass("reading");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,name:true});
            setSites(sites);
        }
    }

    function sortReading(e) {
        console.log("sort by reading:",sortConfig,props.sites);

        if (sortConfig.reading == true) {
            props.sites.sort((a, b) => {
                if(a.level < b.level) {
                    return -1;
                } 
    
                if (a.level > b.level) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state");
            setReadingClass("reading descend");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,reading:false});
            setSites(sites);
        } else {
            props.sites.sort((a, b) => {
                if(a.level < b.level) {
                    return 1;
                } 
    
                if (a.level > b.level) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state");
            setReadingClass("reading ascend");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,reading:true});
            setSites(sites);
        }
    }

    function sortState(e) {
        console.log("sort by state:",sortConfig);

        if (sortConfig.state == true) {
            props.sites.sort((a, b) => {
                if(a.active < b.active) {
                    return -1;
                } 
    
                if (a.active > b.active) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state ascend");
            setReadingClass("reading");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,state:false});
            setSites(sites);
        } else {
            props.sites.sort((a, b) => {
                if(a.active < b.active) {
                    return 1;
                } 
    
                if (a.active > b.active) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state descend");
            setReadingClass("reading");
            setStatusClass("status");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,state:true});
            setSites(sites);
        }
    }

    function sortStatus(e) {
        console.log("sort by status:",sortConfig);

        if (sortConfig.status == true) {
            sites.sort((a, b) => {
                if(a.site.flood < b.site.flood) {
                    return -1;
                } 
    
                if (a.site.flood > b.site.flood) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state");
            setReadingClass("reading");
            setStatusClass("status ascend");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,status:false});
            setSites(sites);
        } else {
            sites.sort((a, b) => {
                if(a.site.flood < b.site.flood) {
                    return 1;
                } 
    
                if (a.site.flood > b.site.flood) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStateClass("state");
            setReadingClass("reading");
            setStatusClass("status descend");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,status:true});
            setSites(sites);
        }
    }

    return (
    <table className={"stage table"} >
        <colgroup>
            <col width="5%"/>
            <col width="50%"/>
            <col width="10%"/>
            <col width="15%"/>
            <col width="15%"/>
        </colgroup>
        <thead style={{height:36}}>
            <tr>
                <th className={stateClass}        onClick={sortState}>State</th>
                <th className={nameClass}         onClick={sortName}>Name</th>
                <th className={readingClass}      onClick={sortReading}>Reading</th>
                <th className={statusClass}       onClick={sortStatus}>Status</th>
                <th className={lastUpdateClass}   onClick={sortLastUpdate}>Last Update</th>
            </tr>
        </thead>
    <tbody>
    {
        props.sites.map((site,i) => {
            var active = {"paddingLeft":5};
            var lastUpdate;
            var level;
            var levelMM;
            var reading;
            var status;
            var threshold;

            itemStyle.display = site.visible;

            console.log("Site:",site);

            if(site.message != null) {
                lastUpdate = new Date(site.message.timestamp * 1000).toLocaleString("en-US",{month:"2-digit", day:"2-digit", year:"2-digit", hour:"2-digit", minute:"2-digit", second:"2-digit"})
                if(site.active) {
                    active.color = "green";
                    status = "Online";
                } else {
                    active.color = "red";
                    status = "Offline";
                }

                reading = findReading(site.readings,"stage");

                if(site.message.maxbotix != null) {
                    levelMM = (reading.elevation - site.message.maxbotix.mm);
                    level = (levelMM / 304.8).toFixed(2);
                    threshold = findThreshold(reading.thresholds,levelMM);
                } else if(site.message.senix != null) {
                    levelMM = (reading.elevation - site.message.senix.mm);
                    level = (levelMM / 304.8).toFixed(2);
                    threshold = findThreshold(reading.thresholds,levelMM);
                } else if(site.message.csi != null) {
                    levelMM = (reading.elevation - site.message.csi.basic.ft) * 304.8;
                    level = (levelMM / 304.8).toFixed(2);
                    threshold = findThreshold(reading.thresholds,levelMM);
                } else {
                    levelMM = 0;
                    level = 0;
                    threshold = "unknown"
                }
            } else {
                lastUpdate = "--/--/-- --:--";
                levelMM = 0;
                level = 0;
                active.color = "red";
                status = "Offline";
                threshold = {status:"unknown",color:'green'}
            }

            thresholdStatusStyle.backgroundColor = threshold.color;
            console.log("threshold:",threshold);

            return(
                <tr
                    style={{height:36,cursor:"pointer"}} 
                    key={i} 
                    onClick={(e) => handleRowClick(e,site.tags.dashboard,site.sk)} 
                    onMouseEnter={(e) => handleMouseEnter(e)} 
                    onMouseLeave={(e) => handleMouseLeave(e)}
                >
                    <td style={active}>{status}</td>
                    <td>{site.name}</td>
                    <td style={{textAlign:"left"}}>{level + " ft."}</td>
                    <td>
                        <div style={{margin:"auto"}}>
                            <div style={thresholdStatusStyle}></div>
                            <div style={thresholdNameStyle}>{threshold.status}</div>
                        </div>
                    </td>
                    <td>{lastUpdate}</td>
                </tr>
            )
        })
    }
    </tbody>
  </table>
  )
}
