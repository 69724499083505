import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link,useSearchParams,useNavigate } from "react-router-dom";

import AppBar from '../../components/AppBar.js';
import Cards from "../../components/cards/overview/Cards.js";
import Drawer from '../../components/Drawer.js';
import Grid from '../../components/Grid.js';
import GridItem from '../../components/GridItem.js';
import MenuButton from '../../components/buttons/Menu.js';
import MenuItem from '../../components/menu/MenuItem.js';
import MenuSubTile from '../../components/menu/MenuSubTitle.js';
import SearchBar from '../../components/SearchBar.js';
import Shadow from '../../components/Shadow.js';
import Spacer from '../../components/menu/Spacer.js';

import "../style.css";

import { AuthContext } from '../../App.js';
import { ThemeContext } from '../../App.js';
import { urlContext } from '../../App.js';
import { keyContext } from '../../App.js';

var online = 0;
var offline = 0;
var enabled = 0;

export default function SiteGrid(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const asURL = useContext(urlContext);
    const asKey = useContext(keyContext);

    const elemRef = useRef([]);

    const [drawer, setDrawer] = useState(false);
    const [sites, setSites] = useState([]);
    const [title, setTitle] = useState("");
    const [shadow, setShadow] = useState(false);
    const [reading, setReading] = useState("stage");

    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();
    const url = window.location.href;
    const custID = url.split("=")[1];

    // const custID = queryParameters.get("id");

    {console.log("Sites:customer:",custID)}
    // {console.log("Sites:url:",asURL)}
    // {console.log("Sites:key:",asKey)}

    useEffect(() => {
        getSites()
    },[props]);

    function getSites(){
        const queryStr = {
            query: `query GetSites($id:ID!) {
                getSites(id:$id) {
                    customer {
                        abbreviation,
                        name
                    },
                    sites {
                        pk,
                        sk,
                        active,
                        enabled,
                        lastUpdate,
                        message {
                            id,
                            thingName,
                            timestamp,
                            ina219 {
                              current,
                              voltage
                            },
                             bq27441 {
                                voltage,
                                current,
                                power,
                                capacity
                            },
                            lte {
                              rssi
                            },
                            lufft {
                                airPress {
                                    abs
                                },
                                airTemp,
                                precip {
                                    abs,
                                    intensity,
                                    isRaining,
                                    type
                                }
                            },
                            senix {
                              mm,
                              temp
                            },
                            maxbotix {
                                mm
                            },
                            csi {
                                basic {
                                    ft
                                }
                            }
                        },
                        name,
                        readings {
                            name,
                            type,
                            thresholds {
                              color,
                              name,
                              value
                            },
                            min,
                            max,
                            elevation,
                            MLLW,
                            unit
                        },
                        tags {
                            dashboard
                            summary
                            timeout
                        },
                        device
                    }
                }
            }`,
            variables: {
                "id":custID
            }
        }
        fetch(asURL,{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":asKey
            },
            body:JSON.stringify(queryStr)
        })
        .then(resp => resp.json())
        .then(resp => {
            var sortedSites;

            online = 0;
            offline = 0;
            enabled = 0;

            console.log("Resp:",resp.data.getSites);

            setTitle(resp.data.getSites.customer.abbreviation);

            // Calculate totals
            resp.data.getSites.sites.forEach(site => {
                site["visible"] = 'block';

                if(site.enabled) {
                    enabled += 1;

                    if(site.active) {
                        online += 1;
                    } else {
                        offline += 1;
                    }
                }
            })
            
            sortedSites = resp.data.getSites.sites.sort((a, b) => {
                if(a.name < b.name) {
                    return -1;
                } 

                if (a.name > b.name) {
                    return 1;
                }
                
                return 0;
            });

            console.log("Sorted Sites:",sortedSites);
            setSites(sortedSites);
        })
    };

    const menuButtonStyle = {
        position:'absolute',
        top:4,
        left:5,
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const gridStyle = {
        marginTop:165
    }

    const itemStyle = {
        display:"block"
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const searchStyle = {
        position:'fixed',
        top:96,
        zIndex:1
    }

    const statusStyle = {
        position:'fixed',
        top:48,
        left:0,
        right:0,
        height:48,
        background:'rgb(255,255,255)',
        lineHeight:"48px",
        borderBottom:"solid 1px rgb(240,240,240)",
        textAlign :'center',
        zIndex:1
    }

    const tableLinkStyle = {
        position:'absolute',
        top:4,
        right:5,
        fontSize:21,
        textDecoration: 'none'
    }

    const tableStyle = {
        position:'relative',
        color:'white'
    }

    function onClear(e) {
        for(var i in elemRef.current) {
            elemRef.current[i].style.display = "block";
        }
    }

    function onMenu(e) {
        if(drawer) {
            setDrawer(false);
            setShadow(false);
        } else {
            setDrawer(true);
            setShadow(true);
        }
    }

    function onSearch(e) {
        var tmp = sites;

        console.log("onSearch:",e.target.value.toLowerCase());

        for(var i in sites) {
            // console.log("site:",sites[i].name.toLowerCase());
            // console.log("element:",elemRef.current[i]);
            
            if(sites[i].name.toLowerCase().includes(e.target.value.toLowerCase())) {
                elemRef.current[i].style.display = "block";
            } else {
                elemRef.current[i].style.display = "none";
            }
        }
    }

    function onShadow() {
        setShadow(false);
        setDrawer(false);
    }

    return(
        <div className="sites grid" onClick={props.onClick}>
            <Shadow visible = {shadow} onClose={onShadow}/>
            <Drawer 
                visible={drawer} 
                anchor={"left"}
                title = "Menu"
                onClose={onMenu}
            >
                <MenuItem enabled={true} onSelect={() => navigate("/")}>Home</MenuItem>
                <Spacer></Spacer>
                <MenuSubTile>VIEWS</MenuSubTile>
                <MenuItem enabled={false} >Grid</MenuItem>
                <MenuItem enabled={true} onSelect={() => navigate("/Sites/Table?id=" + custID)}>Table</MenuItem>

            </Drawer>
            <AppBar className="appBar" title={title + " Sites"}>
                <MenuButton 
                    style={menuButtonStyle}
                    onSelect={onMenu}
                    >
                </MenuButton>
                {/* <Link style={backLinkStyle} to="/">
                    <BackButton style={backStyle}></BackButton>
                </Link> */}
                {/* <Link style={tableLinkStyle} to="/SitesTable">
                    <TableButton style={tableStyle}></TableButton>
                </Link> */}
            </AppBar>
            <div className="statusBar" style={statusStyle}>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Total:</span>
                <span style={{fontSize:21}}>{enabled}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Online:</span>
                <span style={{fontSize:21, color:'rgb(100, 180, 0)'}}>{online}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Offline:</span>
                <span style={{fontSize:21, color:'rgb(255, 0, 0)'}}>{offline}</span>
            </div>
            <SearchBar onChange={onSearch} onClear={onClear} style={searchStyle}></SearchBar>
            <Grid style={gridStyle} className="cards">
                {
                    sites.map((site,i) => {
                        itemStyle.display = site.visible;
                        if(site.enabled) {
                            return (
                                <GridItem key={i} style={itemStyle} ref={(el) => (elemRef.current[i] = el)}  >
                                    <Link style={linkStyle} to={'/' + site.tags.dashboard + '?id=' + site.sk} >
                                        {Cards(site.tags.summary,site)}
                                    </Link>
                                </GridItem>
                            )
                        }   
                    })
                }
            </Grid>
        </div>
    )
};